/* :root {
    --primaryfontcolor: #f5f5f5;
    --txtstandard16px: 16px;
    --txtbold14px: 14px;
    --btnradiusprimary: 5px;
    --btncolormain2: #9cc5d6;
    --txtheading36px: 36px;
    --txtstandard24px: 24px;
    --btntxtcolormain: #f5f5f5;
    --btncolormain: #a5c3be;
    --txtstandard20px: 20px;
    --txtspacing25px: 25px;
    --btnlinkgap: 25px;
    --bordercolorprimary: #f7f7f7;
    --subtextcolorprimary: #bebebe;
    --txtstandard18px: 18px;
    --dropdownbackground: #0c0c0c;
    --bordercolorseconarygray: #4a4a4a;
    --technologyboxcolor: #5a8c95;
    --txtheading24px: 24px;
    --icon20px: 20px;
    --linktextcolorprimary: #2dc4ff;
    --backgroundmain1: #1a1a1a;
  } */

  :root {
    --primaryfontcolor: #000000;
    --txtstandard16px: 16px;
    --txtbold14px: 14px;
    --btnradiusprimary: 5px;
    /* --btncolormain2: #9cc5d6; */
    --txtheading36px: 36px;
    --txtstandard24px: 24px;
    --btntxtcolormain: #f5f5f5;
    --btncolormain: #90dfff;
    --txtstandard20px: 20px;
    --txtspacing25px: 25px;
    --btnlinkgap: 25px;
    --bordercolorprimary: #1a1a1a;
    --subtextcolorprimary: #858585;
    --txtstandard18px: 18px;
    --dropdownbackground: #e3e3e3;
    --bordercolorseconarygray: #4a4a4a;
    --technologyboxcolor: #6b8d7d;
    --btncolormain2: var(--technologyboxcolor);
    --txtheading24px: 24px;
    --icon20px: 20px;
    --icon28px: 28px;
    --linktextcolorprimary: #2dc4ff;
    --backgroundmain1: #ffffff;
    --portfolioBackgroundMain: #ffffff;
  }
  
  .w-embed-youtubevideo {
    width: 100%;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
    background-position: 50%;
    background-size: cover;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  
  .w-embed-youtubevideo:empty {
    min-height: 75px;
    padding-bottom: 56.25%;
  }
  
  .portfolio-container {
    width: 50%;
    max-width: 1072px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 220px;
    display: flex;
  }
  
  .portfolio-body {
    color: var(--primaryfontcolor);
    /* background: linear-gradient(-195deg, #bebebe, #a95858, #acfea5, #ffffff);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite; */
    font-family: Ubuntu, Helvetica, sans-serif;
    font-size: var(--txtstandard16px);
    line-height: var(--txtbold14px);
    /* background-color: #1a1a1a; */
    background-color: var(--portfolioBackgroundMain);
    flex-direction: column;
    justify-content: start;
    align-items: center;
    display: flex;
    min-height: 100vh;
    padding-bottom: 150px;
  }

  

/* @keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
} */
  
  .portfolio-nav {
    width: 100%;
    height: 75px;
    border-bottom: 1px solid #b0b0b0;
    justify-content: space-between;
    display: flex;
    position: static;
    top: 0;
  }
  
  .nav-left {
    min-width: 200px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .nav-title {
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
  }
  
  .nav-links-default {
    min-width: 300px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
  
  .nav-link {
    font-size: var(--txtbold14px);
    cursor: pointer;
    font-weight: 700;
  }
  
  .nav-action-btn {
    min-width: 120px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    color: #fff;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
  }

  .nav-action-btn:hover {
    color: #000;
    background-color: #fff;
    border: 2px #000 solid;
  }
  
  .portfolio-hero-section {
    width: 100%;
    min-height: 800px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .portfolio-hero-header {
    margin-top: 0;
    margin-bottom: 0;
  }

  .nav-primary-hamburger-section {
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: var(--backgroundmain1);
    border-top: 1.2px solid #d8d8d8;
    position: relative;
    top: 0;
  }

  .nphs-top {
    width: 100%;
  }

  .nav-btn-link {
    color: #fff;
    font-weight: 700;
    background-color: var(--technologyboxcolor);
    border-radius: 5px;
    border: 2px var(--technologyboxcolor) solid;
    padding: 6px 14px;
    cursor: pointer;
  }

  .nav-btn-link:hover {
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    border: #000 2px solid;
    padding: 6px 14px;
    cursor: pointer;
  }

  .nphs-top-item {
    border-bottom: 1.2px solid #d8d8d8;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .top-left {
    position: absolute;
    top: 0px;
    left: 0px;
    border-left: 6px solid var(--technologyboxcolor);
    border-top: 6px solid var(--technologyboxcolor);
    border-radius: 5px;
    height: 100px;
    width: 100px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-bottom: 6px solid var(--technologyboxcolor);
    border-right: 6px solid var(--technologyboxcolor);
    border-radius: 5px;
    height: 100px;
    width: 100px;
  }

  .nphs-nav-button {
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    display: flex;
  }

  .nphs-nav-button-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }

  .nphs-chevron-symbol {
    width: 12px;
    height: 12px;
  }

  .nphs-subitem-icon {
    width: 28px;
    height: 28px;
  }

  .nphs-bottom {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .nphs-bottom-form {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nphs-bottom-submit-button {
    margin-top: 16px;
    background-color: #2d2d2d;
    color: #fff;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    border: 2px solid #2d2d2d;
    cursor: pointer;
  }

  .nphs-bottom-submit-button:hover {
    border: 2px solid #2d2d2d;
    background-color: #fff;
    color: #2d2d2d;
  }

  .nphs-bottom-form-block {
    margin-bottom: 0;
  }

  .nphst-subitem {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-items: center;
    list-style-type: none;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    padding: 2px;
  }

  .nphst-subitem:hover {
    /* text-decoration: underline; */
    background-color: #d1d1d1;
  }

  .nav-lvl2-subitem {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    display: flex;
  }
  
  .hero-txt-main {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    display: flex;
  }
  
  .portfolio-hero-descriptor {
    font-size: 15px;
    font-weight: 500;
  }
  
  .portfolio-hero-notion-style-image {
    max-height: 135px;
  }
  
  .portfolio-project-section {
    width: 100%;
    min-height: 500px;
  }
  
  .portfolio-project-header {
    font-size: var(--txtheading36px);
    justify-content: center;
    margin-top: 0;
    margin-bottom: 30px;
    text-decoration: none;
    display: flex;
  }
  
  .portfolio-project-block {
    width: 100%;
    height: auto;
    min-height: 275px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-bottom: 1px solid #b0b0b0;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
  }
  
  .portfolio-project-image-div {
    width: 225px;
    height: 225px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
  }
  
  .portfolio-project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .portfolio-project-info-div {
    max-width: 60%;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    flex-direction: column;
    display: flex;
  }
  
  .portfolio-project-info-title {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
  }
  
  .portoflio-project-info-question {
    font-size: 16px;
    font-weight: 500;
    display: flex;
  }
  
  .portfolio-project-info-summary {
    font-size: 16px;
  }
  
  .portfolio-project-container {
    grid-column-gap: 45px;
    grid-row-gap: 45px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .heading {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .portfolio-project-heading {
    margin-top: 0;
    margin-bottom: 20px;
    display: none;
  }
  
  .portfolio-info-links {
    width: 100%;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    align-items: center;
    margin-top: 20px;
    display: flex;
  }
  
  .portfolio-btn-icon {
    width: 24px;
    height: 24px;
    display: block;
  }

  
  
  .portfolio-btn-txt {
    /* color: #fff; */
    font-size: var(--txtstandard18px);
    justify-content: flex-start;
    align-items: center;
    padding: 6px 11px;
    font-weight: 700;
    display: flex;
  }
  
  .portfolio-project-button {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    cursor: pointer;
    background-color: #1f1f1f;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 8px 25px 8px 8px;
    text-decoration: none;
    display: flex;
  }
  
  .portfolio-page-container {
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    display: flex;
  }
  
  .portfolio-page-about-section {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    display: flex;
  }
  
  .portfolio-page-header {
    font-size: var(--txtheading36px);
    line-height: var(--txtheading36px);
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .portfolio-about-text {
    width: auto;
    font-size: var(--txtstandard16px);
    line-height: var(--txtstandard24px);
    font-weight: 500;
  }
  
  .portfolio-hero-center-container {
    min-width: 500px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .portfolio-hero-center-links {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
    margin-top: 40px;
    display: flex;
  }
  
  .portfolio-hero-center-link {
    /* color: var(--btntxtcolormain); */
    font-size: var(--txtstandard16px);
    font-weight: 700;
  }
  
  .portfolio-hero-image-link {
    width: 20px;
    height: 20px;
  }
  
  .portfolio-hero-center-link-div {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    cursor: pointer;
    align-items: center;
    padding: 12px 40px;
    display: flex;
    border: 2px var(--btncolormain2) solid;
    color: #fff;
    text-decoration: none;
  }

  .portfolio-hero-center-link-div:hover {
    border: 2px #000 solid;
    background-color: #fff;
    color: #000;
  }
  
  .portfolio-page-tech-section {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    margin-bottom: 20px;
    display: flex;
  }
  
  .portfolio-page-header-tech {
    width: auto;
    max-width: none;
    font-size: var(--txtheading36px);
    line-height: var(--txtheading36px);
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 20px;
    display: flex;
  }
  
  .portfolio-page-tech-div {
    width: 100%;
    flex-direction: column;
    display: block;
  }
  
  .portfolio-about-sub-text {
    width: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  
  .portfolio-page-afk {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
  }
  
  .technology-list {
    width: 100%;
    max-width: 792px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
  }
  
  .portfolio-page-tech-block {
    font-size: 18px;
  }
  
  .technology-icon-img {
    height: 100%;
  }
  
  .technology-item {
    color: white;
    height: auto;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    background-color: var(--technologyboxcolor);
    cursor: pointer;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-weight: 700;
    display: flex;
    box-shadow: 4px 4px 10px -2px #000;
    transition: all 200ms ease;
  }

  .technology-item:hover {
    box-shadow: 8px 6px 12px -2px #000;
  }
  
  .technology-title {
    font-size: var(--txtstandard16px);
  }
  
  .technology-icon {
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .technology-subheader {
    font-size: var(--txtstandard24px);
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 20px;
    text-align: center;
    padding-bottom: 8px;
  }
  
  .portfolio-project-display-div {
    width: 225px;
    height: 225px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .portfolio-prompt-section {
    width: 60%;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
  }
  
  .portfolio-prompt-header {
    width: 100%;
    font-size: var(--txtheading36px);
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
  }
  
  .portfolio-contact-block {
    width: 100%;
  }
  
  .portfolio-contact-form-block {
    width: 100%;
    display: none;
  }
  
  .portfolio-contact-form {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }
  
  .portfolio-contact-field-label {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .portfolio-contact-text-field {
    border-radius: 2px;
  }
  
  .portfolio-contact-submit-btn {
    width: 150px;
    height: 45px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    font-size: 18px;
    font-weight: 700;
  }
  
  .technology-section {
    width: 100%;
    border-bottom: 1px solid #b0b0b0;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 20px;
    display: flex;
  }
  
  .technology-section-title {
    width: 100%;
    font-size: var(--txtheading36px);
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
  }
  
  .portfolio-contact-textarea {
    min-height: 200px;
    border-radius: 2px;
    margin-bottom: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .portfolio-appreciation-section {
    width: 100%;
    border-top: 1px solid #b0b0b0;
    border-bottom: 1px #b0b0b0;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  
  .portfolio-appreciation-header {
    width: 60%;
    font-size: var(--txtheading36px);
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  
  .appreciation-txt {
    width: 60%;
    font-size: var(--txtstandard20px);
    line-height: var(--txtspacing25px);
    padding-bottom: 20px;
    font-weight: 500;
  }
  
  .portfolio-footer-links {
    grid-column-gap: var(--btnlinkgap);
    grid-row-gap: var(--btnlinkgap);
    justify-content: center;
    margin-top: 40px;
    display: flex;
  }
  
  .portfolio-appreciation-header-copy {
    width: 60%;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  
  .portfolio-tech-stack-about {
    width: 100%;
    border-bottom: 1px #b0b0b0;
    margin-top: 30px;
    padding-bottom: 20px;
    display: none;
  }
  
  .portfolio-hero {
    width: 100%;
    min-height: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 100px;
    display: flex;
  }
  
  .portfolio-hero-container {
    min-height: auto;
    min-width: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    /* border: 2px solid var(--bordercolorprimary); */
    border-radius: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 80px 80px 80px 80px;
    display: flex;
    position: relative;
  }
  
  .hero-txt-h1 {
    font-size: var(--txtheading36px);
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 36px;
  }
  
  .hero-descriptor {
    color: var(--subtextcolorprimary);
    text-align: left;
    padding-top: 8px;
    font-size: 15px;
    font-weight: 500;
  }
  
  .portfolio-center-linklist {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    justify-content: center;
    margin-top: 40px;
    display: flex;
  }
  
  .portfolio-nav-v2 {
    width: 100%;
    height: 75px;
    border-bottom: 2px solid #b0b0b0;
    justify-content: space-between;
    display: flex;
    position: static;
    top: 0;
  }

  .nav-subitem-list {
    grid-column-gap: 3px;
    grid-row-gap: 3px;
    flex-direction: column;
    margin-bottom: 5px;
    padding-left: 0;
    font-size: 16px;
    line-height: 16px;
    display: flex;
  }
  
  .portfolio-hero-link {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    display: flex;
    border: 2px var(--btncolormain2) solid;
    color: var(--btntxtcolormain);
  }

  .portfolio-hero-link:hover {
    border: 2px #000 solid;
    background-color: #fff;
    color: #000;
  }
  
  .portfolio-hero-img {
    width: 20px;
    height: 20px;
  }
  
  .portfolio-hero-link-txt {
    /* color: var(--btntxtcolormain); */
    font-size: 16px;
    font-weight: 700;
  }
  
  .portfolio-project-btn {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    display: flex;
    border-width: 2px;
    border-color: var(--btncolormain2);
    color: #fff;
  }

  .portfolio-project-btn:hover {
    border: 2px #000 solid;
    background-color: #fff;
    color: #000;
  }

  .portfolio-getintouch-btn {
    margin-top: 40px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    display: flex;
    color: #fff;
    border: 2px var(--btncolormain2) solid;

  }

  .portfolio-getintouch-btn:hover {
    border: 2px #000 solid;
    background-color: #fff;
    color: #000;
  }
  
  .project-info-block {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    display: flex;
  }
  
  .projectlbl {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
  }
  
  .projectlbl-descriptor {
    font-size: var(--txtstandard16px);
    line-height: var(--txtstandard18px);
  }
  
  .project-title {
    font-size: var(--txtstandard20px);
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
  }
  
  .hero-container-div {
    min-width: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .hero-main-content {
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .hero-left-nav {
    height: 100%;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    display: none;
  }
  
  .hero-nav-list {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
  }
  
  .list-item {
    list-style-type: none;
  }
  
  .hero-navitem {
    cursor: pointer;
    list-style-type: none;
  }
  
  .hero-navitem-txt {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }
  
  .hero-btn-links {
    grid-column-gap: var(--btnlinkgap);
    grid-row-gap: var(--btnlinkgap);
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
  }
  
  .hero-nav-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 32px;
    text-decoration: underline;
  }
  
  .final-msg-txt {
    width: 60%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }
  
  .hero-descriptor-detail {
    color: var(--primaryfontcolor);
    text-align: left;
    overflow-wrap: normal;
    padding-top: 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }
  
  .text-block {
    line-height: 14px;
  }
  
  .project-status-div {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    align-items: center;
    padding-top: 5px;
    display: flex;
  }
  
  .project-status-txt {
    line-height: 14px;
  }
  
  .status-icon {
    width: 16px;
  }
  
  .contract-btn-form {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .contact-submit {
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    margin-top: 20px;
    padding: 12px 20px;
    font-weight: 700;
  }
  
  .text-block-2 {
    font-size: var(--txtstandard20px);
    line-height: var(--txtspacing25px);
  }
  
  .nav-links-hamburger {
    /* min-width: 300px; */
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    justify-content: flex-end;
    align-items: center;
    display: none;
  }
  
  .nav-hamburger-link-div {
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .hamburger-icon {
    height: 100%;
    /* filter: invert(); */
    cursor: pointer;
  }
  
  .nav-link-div {
    text-align: left;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 25px;
    display: flex;
    position: relative;
  }
  
  .nav-dropdown {
    z-index: 9999;
    min-width: 190px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--dropdownbackground);
    border-radius: 0.5px;
    border-color: #000;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 15px;
    /* display: none; */
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 0%;
    right: auto;
  }
  
  .nav-dropdown-item {
    font-size: var(--txtbold14px);
    line-height: var(--txtbold14px);
    cursor: pointer;
    padding: 8px 10px;
    font-weight: 700;
  }
  
  .nav-dropdown-item:hover, .nav-dropdown-item.nav-dropdown-selected {
    filter: brightness(180%);
    text-decoration: underline;
  }
  
  .nav-dropdown-dualsided {
    z-index: 9999;
    min-width: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-radius: var(--btnradiusprimary);
    background-color: var(--dropdownbackground);
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 0%;
    right: auto;
  }
  
  .nav-dropdown-leftside {
    min-width: 200px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-right: 1px solid var(--bordercolorseconarygray);
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  
  .nav-dropdown-rightside {
    min-width: 320px;
    padding: 5px 8px;
  }
  
  .nav-category-item {
    width: 90%;
    font-size: var(--txtbold14px);
    line-height: var(--txtbold14px);
    cursor: pointer;
    padding: 8px 10px;
    font-weight: 700;
  }
  
  .nav-category-div {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }
  
  .nav-category-div:hover {
    filter: brightness(180%);
    text-decoration: underline;
  }
  
  .nav-category-selected-icon {
    width: 30px;
    /* filter: invert(); */
    position: absolute;
    left: 170px;
  }
  
  .nav-technology-item {
    border-radius: var(--btnradiusprimary);
    background-color: var(--technologyboxcolor);
    font-size: var(--txtbold14px);
    line-height: var(--txtbold14px);
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid var(--technologyboxcolor);
    color: #fff;
    font-weight: 700;
  }

  .nav-technology-item:hover {
    filter: brightness(1.5);
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }
  
  .nav-technology-container {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
  
  .technology-view {
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    align-items: stretch;
    display: flex;
  }
  
  .technology-view-item {
    width: 100%;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-bottom: 1px solid var(--bordercolorprimary);
    flex-direction: column;
    padding-bottom: 15px;
    display: flex;
    margin-top: 40px;
  }

  .technology-view-brighten {
    width: 100%;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-bottom: 1px solid var(--bordercolorprimary);
    flex-direction: column;
    padding-bottom: 15px;
    display: flex;
    margin-top: 40px;
    animation: brightenAnimation 0.8s ease-in-out;
  }
  
  @keyframes brightenAnimation {
    0% {
      transform: scale(1);
      filter: brightness(1);
    }
    50% {
      transform: scale(1.05);
      filter: brightness(2.5);
    }
    100% {
      transform: scale(1);
      filter: brightness(1);
    }
  }
  
  
  .technology-view-header {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .header-txt {
    font-size: var(--txtheading24px);
    line-height: var(--txtheading24px);
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .header-icon {
    height: var(--icon28px);
  }
  
  .technology-details {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    display: flex;
  }
  
  .technology-detail-item {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    display: flex;
  }
  
  .technology-view-subheader {
    font-size: var(--txtstandard16px);
    line-height: var(--txtstandard16px);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
  }
  
  .technology-view-list {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    list-style-type: disc;
    display: flex;
  }
  
  .technology-view-listitem {
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain);
    border: 1px solid #000;
    padding: 8px 10px;
  }
  
  .technology-view-txt {
    line-height: var(--txtstandard16px);
  }
  
  .technology-detail-listview-item {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    flex-direction: column;
    display: flex;
  }
  
  .project-page-container {
    width: 50%;
    height: 100%;
    min-width: 672px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }
  
  .project-block {
    height: auto;
    min-height: 275px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
  }
  
  .project-demo-header {
    font-size: var(--txtstandard20px);
    line-height: var(--txtstandard20px);
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .demo-container {
    width: 450px;
    min-width: 300px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .project-header-div {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    display: flex;
  }
  
  .project-header-link {
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .text-block-3 {
    color: var(--linktextcolorprimary);
    font-weight: 700;
    text-decoration: none;
  }
  
  .project-page-title {
    width: 100%;
    font-size: var(--txtheading36px);
    line-height: var(--txtheading36px);
  }
  
  .portfolio-formblock {
    width: 72%;
  }
  
  .portoflio-form {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  
  .portfolio-field-lbl {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .portoflio-textarea {
    min-height: 200px;
  }
  
  .portfolio-submit {
    border-radius: var(--btnradiusprimary);
    background-color: var(--btncolormain2);
    font-size: var(--txtstandard20px);
    line-height: var(--txtstandard20px);
    margin-top: 10px;
    padding: 14px 24px;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid var(--btncolormain2);
  }

  .portfolio-submit-disabled {
    border-radius: var(--btnradiusprimary);
    background-color: rgb(132, 132, 132);;
    font-size: var(--txtstandard20px);
    line-height: var(--txtstandard20px);
    margin-top: 10px;
    padding: 14px 24px;
    font-weight: 700;
    cursor: pointer;
    color: #000;
    border: 2px solid rgb(132, 132, 132);
  }

  .portfolio-submit:hover {
    border: 2px solid #000;
    background-color: #fff;

  }
  
  .portfolio-form-header {
    font-size: var(--txtheading36px);
    line-height: var(--txtheading36px);
    margin-bottom: 40px;
  }


  @media screen and (max-width: 1080px) {
    .nav-links-default {
        display: none;
    }

    .nav-links-hamburger {
        display: flex;
    }
    .portfolio-container {
      width: 512px;
    }
  
    .portfolio-project-image-div {
      display: none;
    }
  
    .portfolio-project-info-div {
      max-width: 100%;
    }
  
    .portfolio-project-container {
      justify-content: flex-end;
    }
  
    .portfolio-page-container {
      width: 65%;
    }
  
    .portfolio-page-header {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  
    .portfolio-about-text {
      width: 100%;
    }
  
    .portfolio-page-header-tech {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center;
    }
  
    .portfolio-about-sub-text {
      width: 100%;
    }
  
    .portfolio-prompt-section {
      width: 80%;
    }
  
    .portfolio-appreciation-header, .appreciation-txt {
      width: 75%;
    }
  
    .portfolio-footer-links {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  
    .portfolio-hero-container {
      min-width: auto;
      padding: 50px 50px 50px 50px;
    }
  
    .final-msg-txt {
      width: 75%;
    }
  
    .project-page-container {
      width: 70%;
    }
  }
  
  @media screen and (max-width: 767px) {
    .portfolio-container {
      width: 292px;
    }

    .portfolio-hero-notion-style-image {
      max-width: 200px;
      display: none;
    }
  
    .portfolio-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .nav-left {
      /* justify-content: center; */
      /* padding-top: 10px; */
    }
  
    .portfolio-hero-section {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  
    .portfolio-page-container {
      width: 80%;
    }
  
    .technology-subheader {
      text-align: center;
    }
  
    .portfolio-prompt-section {
      width: 90%;
    }
  
    .portfolio-appreciation-header, .appreciation-txt {
      width: 80%;
    }
  
    .portfolio-footer-links {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }

    .hero-btn-links {
      flex-direction: column;
    }
  
    .portfolio-hero {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  
    /* .portfolio-nav-v2 {
      flex-direction: column;
      align-items: center;
    } */
  
    .final-msg-txt {
      width: 80%;
    }
  
    .project-page-container {
      width: 75%;
      min-width: 400px;
    }
  
    .demo-container {
      width: 362px;
      min-width: 100px;
    }

    .project-header-div {
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
    }
  }
  
  @media screen and (max-width: 479px) {
    .nav-subitem-list {
      margin-bottom: 6px;
    }

    .portfolio-hero-container {
      /* padding: 20px 20px 20px 20px; */
    }

    .nav-lvl2-subitem {
      grid-column-gap: 6px;
      grid-row-gap: 6px;
      flex-wrap: wrap;
      align-content: stretch;
    }

    .nav-btn-link {
      cursor: pointer;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }

    .portfolio-container {
      width: 224px;
    }
  
    .portfolio-nav {
      height: auto;
      flex-direction: column;
    }
  
    .nav-left {
      margin-top: 15px;
      margin-bottom: 20px;
      justify-content: center;
      padding-top: 10px;
    }
  
    .nav-links-default {
      flex-direction: column;
      padding-bottom: 20px;
    }
  
    .portfolio-hero-section {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      flex-direction: column;
    }
  
    .portfolio-hero-notion-style-image {
      max-width: 200px;
      display: none;
    }
  
    .portfolio-page-container {
      width: 90%;
    }
  
    .portfolio-page-about-section {
      align-items: flex-start;
    }
  
    .portfolio-page-header {
      font-size: 48px;
    }
  
    .portfolio-hero-center-container {
      min-width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .portfolio-hero-center-links {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      flex-direction: column;
    }
  
    .portfolio-appreciation-header, .appreciation-txt {
      width: 80%;
    }
  
    .portfolio-footer-links, .portfolio-hero {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      flex-direction: column;
    }
  
    .portfolio-hero-container {
      min-width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .hero-txt-h1, .hero-descriptor {
      text-align: center;
    }
  
    .portfolio-center-linklist {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      flex-direction: column;
    }
  
    .portfolio-nav-v2 {
      height: auto;
      flex-direction: column;
    }
  
    .hero-btn-links {
      flex-direction: column;
    }
  
    .final-msg-txt {
      width: 80%;
    }
  
    .nav-links-hamburger {
      flex-direction: column;
      padding-bottom: 20px;
    }
  
    .project-page-container {
      width: 80%;
      min-width: 160px;
    }
  
    .demo-container {
      width: 212px;
      min-width: 0;
    }

    .portfolio-info-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      align-items: start;
      margin-top: 20px;
      display: flex;
    }
  }
  